import Link from 'next/link'
import { ContainedButton } from './core/Button'
import Logo from './layout/Logo'

const ErrorPage = ({
  showLogo = true,
  ImageComponent = null,
  title,
  description = null,
  Content = null,
  action = null,
}) => {
  return (
    <div className="error-page flex justify-center w-full min-h-screen min-h-screen-ios bg-background text-backgroundContrast">
      <div className="error-page-inner flex flex-col space-y-4 justify-between items-center w-full max-w-[500px] px-4 py-8">
        {showLogo && (
          <Link href="/sports" className="error-page-inner-logo-wrapper flex h-full w-full">
            <Logo height="32px" />
          </Link>
        )}

        <div className="error-page-inner-content flex flex-col justify-center items-center text-center space-y-6 w-full h-full">
          {ImageComponent && <ImageComponent />}
          <span className="error-page-inner-content-title text-2xl uppercase font-bold">{title}</span>
          {description && <span className="error-page-inner-content-description text-sm leading-6">{description}</span>}
          {Content && <Content />}
        </div>

        {action && (
          <div className="error-page-inner-action flex w-full">
            <Link href="/sports" legacyBehavior>
              <ContainedButton className="error-page-inner-action-button rounded-full h-[52px]" fullWidth>
                {action}
              </ContainedButton>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ErrorPage
