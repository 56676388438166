
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import ErrorPage from '../components/ErrorPage';
import Error404 from '../public/404.svg';
const Error404Page = () => {
    const { t } = useTranslation('common');
    return (<ErrorPage ImageComponent={Error404} title={t('Error404Page.title')} description={t('Error404Page.description')} action={t('Error404Page.action')}/>);
};
export default Error404Page;

    async function __Next_Translate__getStaticProps__195f6fe6471__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f6fe6471__ as getStaticProps }
  